body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 1.8;
    padding: 0;
    margin: 0;
    color: #777d74;
    background: #f3f7fd;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#dropdown-action::after {
    content: none;
}

.cursor-pointer {
    cursor: pointer !important;
}

.setting-remind {
    animation: blinker 1.5s linear infinite;
    display: flex;
    margin: 25px 0 0 30px;
    float: left;
}

@keyframes blinker {
    50% {
        opacity: 0;
        /* transform: scale(1.2); */
    }
}